import React, { useState } from 'react';
import axios from 'axios';

import Layout from '../../components/Layout';
import Personal from '../../components/Personal';
import HomeInfo from '../../components/HomeInfo';

const API_URL = process.env.GATSBY_API_URL || 'http://localhost:9000';

const Renters = () => {
  const [personalInfo, setPersonalInfo] = useState({});
  const [info, setInfo] = useState({
    rentalType: 'home',
    sameAddress: '',
    city: '',
    state: '',
    zip: '',
    address: '',
    personalPropertyLimit: '',
    dog: '',
    dogCount: '',
    dogBreed: '',
    desiredEffectiveDate: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const onInfoChange = (key, value) => {
    setInfo({ ...info, [key]: value });
  };
  const onSubmit = async () => {
    try {
      await axios.post(`${API_URL}/quote`, {
        personalInfo,
        info,
      });
      setSubmitted(true);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Layout fullMenu>
      <article id="main">
        <header style={{ height: 300 }}>
          <h2>Realtors</h2>
        </header>
        <section className="wrapper style5">
          <div
            className="inner"
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'space-around',
              flexWrap: 'wrap',
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: 1,
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <video
                height="500"
                width="100%"
                style={{ backgroundColor: 'black' }}
                poster="https://www.agencyinsurance.us/static/logo-88948cbfc8cd8be31da8ae31dce8e768.png"
                controls
              >
                <source src="https://storage.googleapis.com/agencyinsurance/Agency%20Insurance_DRAFT.mov" />
              </video>
            </div>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default Renters;
